.bg-primary {
  background: #2196f3 !important;
}

.bg-white {
  background: white !important;
}
.dark {
  background: #000000;
}
.light-dark {
  background: #212121;
  color: #fff;
}
.text-white {
  color: #fff;
}
.text-light-white {
  color: rgba(255, 255, 255, 0.54);
}
.text-muted {
  color: rgba(0, 0, 0, 0.54);
}
.text-gray {
  color: rgba(0, 0, 0, 0.74);
}
.text-brand {
  color: $brand;
}
