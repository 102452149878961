.fade-in {
  @include keyframeMaker(fade-in) {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: fade-in 1s #{bezier()};
}
